import React from 'react';
import { Layout, Stack, Main } from '@layout';
import { Heading, Text } from 'theme-ui';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';

const PagePrivacyPolicy = props => {
  return (
    <Layout {...props}>
      <Seo title='プライバシーポリシー' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="プライバシーポリシー"
            subheader=""
          />
          <Divider />
          <Heading variant="h3">個人情報の利用目的</Heading>
          <Text variant="p">当ブログでは、お問い合わせの際、名前やメールアドレス等の個人情報を入力いただく場合がございます。<br />
            取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。</Text>
          <Divider />
          <Heading variant="h3">アクセス解析ツールについて</Heading>
          <Text variant="p">当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。<br />
            トラフィックデータは匿名で収集されており、個人を特定するものではありません。</Text>
          <Divider />
          <Heading variant="h2">免責事項</Heading>
          <Text variant="p">当ブログからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。<br />
            また当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。<br />
            当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</Text>
          <Divider />
          <Heading variant="h2">著作権について</Heading>
          <Text variant="p">当ブログで掲載している文章や画像などにつきましては、無断転載することを禁止します。</Text>
          <Text variant="p">当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。</Text>
          <Divider />
          <Heading variant="h2">リンクについて</Heading>
          <Text variant="p">当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。<br />
            ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</Text>
        </Main>
      </Stack>
    </Layout>
  );
};

export default PagePrivacyPolicy;
